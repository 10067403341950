<template>
  <div id="app">
    <span
      :style="`background-image: url(img/${$company}.jpg);`"
      class="bg"
    />
    <v-app>
      <pages-core-app-bar />
      <v-container
        class="error-page fill-height"
        tag="section"
      >
        <v-row
          class="text-center"
          justify="center"
        >
          <v-col cols="auto">
            <h1 class="title font-weight-black">
              401
            </h1>

            <div class="display-3 mb-5 mt-10">
              {{ $t('pages.unauthorized.Unauthorized_URL_link') }}
            </div>

            <v-btn
              depressed
              @click="goToMain"
            >
              {{ $t('pages.unauthorized.Back_to_the_application') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <pages-core-footer />
    </v-app>
  </div>
</template>

<script>

  export default {
    name: 'PagesUnauthorized',

    components: {
      PagesCoreAppBar: () => import('@/views/pages/components/core/AppBar'),
      PagesCoreFooter: () => import('@/views/pages/components/core/Footer'),
    },
    methods: {
      goToMain () {
        this.$router.push({ name: 'Dashboard' })
      },
    },
  }
</script>

<style scoped>
.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    filter: brightness(40%);
    /* background-color: red; */
    transform: scale(1.1);
  }
</style>
